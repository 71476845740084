// store/modules/user.ts
import {
  IBooklet,
  IBox,
  IRoll,
  Finish,
  IFilterTip,
  ICollection,
} from "@/interfaces/products";
import axios from "axios";
import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import store from "./index";

@Module({ namespaced: true, name: "products", dynamic: true, store: store })
class Products extends VuexModule {
  _booklets: IBooklet[] = [];
  _boxes: IBox[] = [];
  _rolls: IRoll[] = [];
  _filterTips: IFilterTip[] = [];
  _collections: ICollection[] = [];

  _partialFinishes: Finish[] = [];
  _fullFinishes: Finish[] = [];

  get booklets() {
    return this._booklets;
  }

  get rolls() {
    return this._rolls;
  }

  get filterTips() {
    return this._filterTips;
  }

  get boxes() {
    return this._boxes;
  }

  get collections() {
    return this._collections;
  }

  get partialFinishes() {
    return this._partialFinishes;
  }

  get fullFinishes() {
    return this._fullFinishes;
  }

  get products() {
    return [
      ...this.booklets,
      ...this.boxes,
      ...this.rolls,
      ...this.filterTips,
      ...this.collections,
    ];
  }

  @Mutation
  public SET_BOOKLETS(booklets: IBooklet[]) {
    this._booklets = booklets;
  }

  @Mutation
  public SET_PARTIAL_FINISHES(finishes: Finish[]) {
    this._partialFinishes = finishes.filter(
      (finish) => finish.partial === true
    );
  }

  @Mutation
  public SET_FULL_FINISHES(finishes: Finish[]) {
    this._fullFinishes = finishes.filter((finish) => finish.full === true);
  }

  @Mutation
  public SET_BOXES(boxes: IBox[]) {
    this._boxes = boxes;
  }

  @Mutation
  public SET_COLLECTIONS(collections: ICollection[]) {
    this._collections = collections;
  }

  @Mutation
  public SET_ROLLS(rolls: IRoll[]) {
    this._rolls = rolls;
  }

  @Mutation
  public SET_FILTER_TIPS(filterTips: IFilterTip[]) {
    this._filterTips = filterTips;
  }

  @Action({ rawError: true })
  public async fetchBooklets(): Promise<void> {
    if (this._booklets.length !== 0) return;
    const response = await axios.get("/booklets");
    this.SET_BOOKLETS(response.data.booklets);
  }

  @Action({ rawError: true })
  public async fetchCollections(): Promise<void> {
    if (this._collections.length !== 0) return;
    const response = await axios.get("/collections");
    this.SET_COLLECTIONS(response.data.collections);
  }

  @Action({ rawError: true })
  public async fetchBoxes({
    boxable_type,
    boxable_id,
  }: {
    boxable_type?: string;
    boxable_id?: number;
  } = {}): Promise<void> {
    const response = await axios.get("/boxes", {
      params: {
        boxable_type: boxable_type,
        boxable_id: boxable_id,
      },
    });
    this.SET_BOXES(response.data.boxes);
  }

  @Action({ rawError: true })
  public async fetchRolls(): Promise<void> {
    const response = await axios.get("/rolls");
    this.SET_ROLLS(response.data.rolls);
  }

  @Action({ rawError: true })
  public async fetchFilterTips({
    standalone,
    tipable_type,
    tipable_id,
  }: {
    standalone?: boolean;
    tipable_type?: string;
    tipable_id?: number;
  } = {}): Promise<void> {
    const response = await axios.get("/filter-tips", {
      params: {
        standalone: standalone === true ? 1 : standalone === false ? 0 : "",
        tipable_type: tipable_type ?? "",
        tipable_id: tipable_id ?? "",
      },
    });
    this.SET_FILTER_TIPS(response.data.filter_tips);
  }

  @Action({ rawError: true })
  public async fetchFinishes(): Promise<void> {
    const response = await axios.get("/finishes");
    const finishes = response.data.finishes.map(
      (finish: { partial: number; full: number }) => {
        return {
          ...finish,
          partial: finish.partial == 1,
          full: finish.full == 1,
        };
      }
    );

    this.SET_PARTIAL_FINISHES(finishes);
    this.SET_FULL_FINISHES(finishes);
  }

  @Action({ rawError: true })
  public fetchProducts() {
    this.context.dispatch("fetchBooklets");
    this.context.dispatch("fetchBoxes");
    this.context.dispatch("fetchRolls");
    this.context.dispatch("fetchFilterTips");
  }
}

export default Products;
