enum QuotePermissions {
  indexQuote = "INDEX_QUOTE",

  storeQuote = "STORE_QUOTE",

  updateQuote = "UPDATE_QUOTE",
  updateQuoteOwned = "UPDATE_QUOTE_OWNED",

  showQuote = "SHOW_QUOTE",
  showQuoteOwned = "SHOW_QUOTE_OWNED",

  deleteQuote = "DELETE_QUOTE",
}

export default QuotePermissions;
