import { Options, Vue } from "vue-class-component";

import { NDataTable, NDropdown, NModal } from "naive-ui";

import { getModule } from "vuex-module-decorators";
import store from "@/store/index";
import products from "@/store/products";
import { RowData } from "naive-ui/es/data-table/src/interface";
const productsModule = getModule(products, store);
@Options({
  components: {
    NDropdown,
    NModal,
    NDataTable,
  },
})
export default class ProductViewer extends Vue {
  options = [
    {
      label: "Booklets",
      key: "BK",
    },
    {
      label: "Boxes",
      key: "BX",
    },
    {
      label: "Rolls",
      key: "RL",
    },
    {
      label: "Filter Tips",
      key: "FT",
    },

    {
      label: "Collections",
      key: "CL",
    },
  ];

  modal = {
    open: false,
    title: "Title",
  };

  columns = [
    {
      title: "Name",
      key: "name",
    },
    {
      title: "Code",
      key: "code",
    },
    {
      title: "Tool",
      key: "cutting_tool",
    },
  ];

  filter = "";

  products: unknown[] = [];

  paginationInfo = {
    pageSize: 10,
  };

  async handleSelect(val: string) {
    switch (val) {
      case "BK":
        if (this.booklets.length === 0) await productsModule.fetchBooklets();
        this.products = this.booklets;
        this.modal.title = "Booklets";
        this.modal.open = true;
        break;

      default:
        throw "SHIT";
    }
  }

  mounted() {
    this.handleSelect("BK");
  }

  get booklets() {
    return productsModule.booklets;
  }

  get filteredProducts() {
    if (this.filter.length)
      return this.products.filter((product: any) => {
        console.log({ name: product.name, filter: this.filter });
        return (product.name as string).toLowerCase().includes(this.filter);
      });
    return this.products;
  }

  rowProps = (row: RowData) => {
    return {
      style: "cursor: pointer;",
      onClick: () => {
        console.log(`Clicked row: ${row.id}`);
      },
    };
  };
}
