import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/settings",
    name: "SettingsPage",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/page/SettingsPage.vue"
      ),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "users",
        name: "UserPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/user-page/UserPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "roles",
        name: "RolePage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/role-page/RolePage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "booklets",
        name: "BookletPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/products/booklet-page/BookletPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "rolls",
        name: "RollPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/products/roll-page/RollPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "filter-tips",
        name: "FilterTipPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/products/filter-tip-page/FilterTipPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "collections",
        name: "CollectionPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/products/collection-page/CollectionPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "boxes",
        name: "BoxPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/products/box-page/BoxPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "sheet-materials",
        name: "SheetMaterialPage",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/settings/production-order/sheet-material-page/SheetMaterialPage.vue"
          ),
      },
    ],
  },
];

export default routes;
