enum DesignProjectPermissions {
  indexDesignProject = "INDEX_DESIGN_PROJECT",

  storeDesignProject = "STORE_DESIGN_PROJECT",

  updateDesignProject = "UPDATE_DESIGN_PROJECT",
  updateDesignProjectOwned = "UPDATE_DESIGN_PROJECT_OWNED",

  showDesignProject = "SHOW_DESIGN_PROJECT",
  showDesignProjectOwned = "SHOW_DESIGN_PROJECT_OWNED",

  deleteDesignProject = "DELETE_DESIGN_PROJECT",

  claimDesignProject = "CLAIM_DESIGN_PROJECT",
  unclaimDesignProject = "UNCLAIM_DESIGN_PROJECT",

  pauseDesignProject = "PAUSE_DESIGN_PROJECT",

  finishDesignProject = "FINISH_DESIGN_PROJECT",
  reclaimDesignProject = "RECLAIM_DESIGN_PROJECT",

  unclaimDesignProjectOwned = "UNCLAIM_DESIGN_PROJECT_OWNED",
  pauseDesignProjectOwned = "PAUSE_DESIGN_PROJECT_OWNED",

  uploadDesignProjectMaterials = "UPLOAD_DESIGN_PROJECT_MATERIALS",
  downloadDesignProjectMaterials = "DOWNLOAD_DESIGN_PROJECT_MATERIALS",

  uploadDesignProjectPrintReadies = "UPLOAD_DESIGN_PROJECT_PRINT_READIES",
  downloadDesignProjectPrintReadies = "DOWNLOAD_DESIGN_PROJECT_PRINT_READIES",

  commentDesignProject = "COMMENT_DESIGN_PROJECT",
}

export default DesignProjectPermissions;
