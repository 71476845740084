import Helper from "./helper";

class Validator {
  public static required(fieldValue: unknown, fieldName: string) {
    if (
      typeof fieldValue === "undefined" ||
      fieldValue === null ||
      fieldValue === ""
    )
      return new Error(fieldName + " is required");

    return true;
  }

  public static afterDate(
    fieldValue: number,
    fieldName: string,
    date?: number
  ) {
    const selectedDate = new Date(fieldValue);
    let dateToCompare = new Date();
    if (date) dateToCompare = new Date(date);

    if (selectedDate.setHours(0, 0, 0, 0) >= dateToCompare.setHours(0, 0, 0, 0))
      return true;

    return new Error(
      `${fieldName} has to be after ${Helper.dateToString(dateToCompare)}`
    );
  }

  public static min(fieldValue: number, fieldName: string, minValue = 0) {
    if (!fieldValue) return;

    if (fieldValue > minValue) return true;

    return new Error(
      `${fieldName} has to be greater than ${minValue.toString()}`
    );
  }

  public static minOrEq(fieldValue: number, fieldName: string, minValue = 0) {
    if (fieldValue >= minValue) return true;

    return new Error(
      `${fieldName} has to be greater or equal to ${minValue.toString()}`
    );
  }

  public static eModulo(fieldValue: number, fieldName: string, step: number) {
    if (!fieldValue || !step) return;

    const remainder = fieldValue % step;
    const division = fieldValue / step;

    if (remainder === 0) return true;

    const prevValue = Math.floor(division) * step;
    const nextValue = Math.ceil(division) * step;

    return new Error(
      `Closest possible values for ${fieldName} are ${prevValue} and ${nextValue}`
    );
  }

  public static modulo(fieldValue: number, step: number) {
    const remainder = fieldValue % step;
    const division = fieldValue / step;

    if (remainder === 0) return { prevValue: 0, nextValue: 0 };

    const prevValue = Math.floor(division) * step;
    const nextValue = Math.ceil(division) * step;

    return { prevValue, nextValue };
  }
}

export default Validator;
