import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {
  // create naive ui
  create,
  // component
  NButton,
  NCard,
  NGrid,
  NGridItem,
  NSpace,
  NIcon,
  NTooltip,
  NInput,
  NMessageProvider,
  NSkeleton,
  NDivider,
  NLoadingBarProvider,
} from "naive-ui";
import axios from "axios";
import VueAxios from "vue-axios";

const naive = create({
  components: [
    NButton,
    NCard,
    NGrid,
    NGridItem,
    NSpace,
    NIcon,
    NTooltip,
    NInput,
    NMessageProvider,
    NSkeleton,
    NDivider,
    NLoadingBarProvider,
  ],
});

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

import Echo from "laravel-echo";
import Pusher from "pusher-js";

declare global {
  interface Window {
    Echo: Echo;
    Pusher: any;
  }
}

// window.Pusher = Pusher;

// console.log(process.env.VUE_APP_WEBSOCKETS_HOST);
// console.log(process.env.VUE_APP_WEBSOCKETS_PORT);

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: "57911027a2a6f40d06b0",
//   cluster: "eu",
//   forceTLS: false,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_HOST ?? window.location.hostname,
//   wsPort: process.env.VUE_APP_WEBSOCKETS_PORT ?? 6001,
//   encrypted: false,
//   enabledTransports: ["ws", "wss"],
// });

// console.log(window.Echo);

createApp(App)
  .use(store)
  .use(router)
  .use(naive)
  .use(VueAxios, axios)
  .mount("#app");

// axios.get("http://127.0.0.1:8000/sanctum/csrf-cookie").then((response) => {
//   console.log(response);
// });
