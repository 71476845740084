import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/design-projects",
    name: "IndexDesignProject",
    component: () =>
      import(
        /* webpackChunkName: "design-project" */ "../views/design-project/index/IndexDesignProject.vue"
      ),
  },
  {
    path: "/design-project/:id",
    name: "ShowDesignProject",
    component: () =>
      import(
        /* webpackChunkName: "design-project" */ "../views/design-project/show/ShowDesignProject.vue"
      ),
  },
  {
    path: "/design-project/create",
    name: "CreateDesignProject",
    component: () =>
      import(
        /* webpackChunkName: "design-project" */ "../views/design-project/create/CreateDesignProject.vue"
      ),
  },
  {
    path: "/design-project/edit/:id",
    name: "EditDesignProject",
    component: () =>
      import(
        /* webpackChunkName: "design-project" */ "../views/design-project/create/CreateDesignProject.vue"
      ),
  },
];

export default routes;
