import { Options, Vue } from "vue-class-component";

import {
  NConfigProvider,
  NGlobalStyle,
  NDrawer,
  NDrawerContent,
  NMenu,
  NPageHeader,
  NLayoutSider,
  NLayoutContent,
  NLayout,
  NBadge,
  NImage,
  NIcon,
  darkTheme,
  lightTheme,
  NNotificationProvider,
} from "naive-ui";

import { Watch } from "vue-property-decorator";
import { MenuOption } from "naive-ui";
import { h } from "vue";
import { RouterLink } from "vue-router";

import { WeatherSunny32Filled, WeatherMoon48Filled } from "@vicons/fluent";

import { Moon, Sunny as Sun, LogOutOutline } from "@vicons/ionicons5";

import { getModule } from "vuex-module-decorators";
import store from "@/store/index";
import app from "@/store/app";
import NotificationSidebar from "./components/widgets/notification-sidebar/NotificationSidebar.vue";

import GeneralPermissions from "./permissions/GeneralPermissions";
import DesignProjectPermissions from "./permissions/DesignProjectPermissions";
import ProductionOrderPermissions from "./permissions/ProductionOrderPermissions";
import QuotePermissions from "./permissions/QuotePermissions";

import Ability from "./ability";

const appModule = getModule(app, store);

@Options({
  components: {
    NConfigProvider,
    NGlobalStyle,
    NDrawerContent,
    NDrawer,
    NIcon,
    NMenu,
    NPageHeader,
    NLayoutSider,
    NLayoutContent,
    NLayout,
    NBadge,
    NImage,
    NNotificationProvider,

    NotificationSidebar,

    WeatherSunny32Filled,
    WeatherMoon48Filled,
    LogOutOutline,
    Moon,

    Sun,
  },
})
export default class App extends Vue {
  active = true;
  selectedItem = "";
  collapsed = true;
  darkTheme = darkTheme;
  lightTheme = lightTheme;
  isDarkMode = true;
  componentMounted = false;
  logout = 0;

  async created() {
    const token = window.localStorage.getItem("token");
    const darkMode = window.localStorage.getItem("darkMode");

    if (darkMode === null) {
      window.localStorage.setItem("darkMode", "1");
      this.isDarkMode = true;
    } else {
      this.isDarkMode = darkMode === "1";
    }

    if (token === null) {
      this.$router.push({ name: "UserLogin" });
    } else {
      this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }

  mounted() {
    this.componentMounted = true;
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    window.localStorage.setItem("darkMode", this.isDarkMode ? "1" : "0");
  }

  get notDashboard() {
    return this.$route.name !== "Dashboard";
  }

  get notLogin() {
    const name = this.$route.name;
    const id = "id" in this.$route.params ? this.$route.params.id : "";

    switch (name) {
      case "Dashboard":
        document.title = "BMS | Dashboard";
        break;

      case "IndexDesignProject":
        document.title = "BMS | Design Projects";
        break;

      case "ShowDesignProject":
        document.title = "BMS | Design Project " + id;
        break;

      case "CreateDesignProject":
        document.title = "BMS | Create Design Project ";
        break;

      case "EditDesignProject":
        document.title = "BMS | Edit Design Project " + id;
        break;

      case "IndexProductionOrder":
        document.title = "BMS | Production Orders";
        break;

      case "ShowProductionOrder":
        document.title = "BMS | Production Order " + id;
        break;

      case "CreateProductionOrder":
        document.title = "BMS | Create Production Order ";
        break;

      case "EditProductionOrder":
        document.title = "BMS | Edit Production Order " + id;
        break;

      case "IndexQuote":
        document.title = "BMS | Quotes";
        break;

      case "ShowQuote":
        document.title = "BMS | Quote " + id;
        break;

      case "CreateQuote":
        document.title = "BMS | Create Quote ";
        break;

      case "EditQuote":
        document.title = "BMS | Edit Quote " + id;
        break;

      default:
        document.title = "BMS";
    }

    return this.$route.name !== "UserLogin";
  }

  get menuOptions(): MenuOption[] {
    return this.navs.map((navBtn) => {
      return {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: navBtn.route,
              },
            },
            { default: () => navBtn.name }
          ),
        key: navBtn.route,
        icon: () => h(NIcon, null, { default: () => h(navBtn.icon) }),
      };
    });
  }

  get logoutMenuOptions(): MenuOption[] {
    return [
      {
        label: () =>
          h(
            "div",
            {
              style: "color:#e88080",
            },

            { default: () => "Logout" }
          ),
        key: 1,
        icon: () =>
          h(NIcon, { color: "#e88080" }, { default: () => h(LogOutOutline) }),
      },
    ];
  }

  handleUpdateValue(key: string, item: MenuOption) {
    console.log("[onUpdate:value]: " + JSON.stringify(key));
    console.log("[onUpdate:value]: " + JSON.stringify(item));
  }

  get user() {
    return appModule.user;
  }

  get navs() {
    let navArr = appModule.navs;

    if (!this.user) return [];

    if (!Ability.can(GeneralPermissions.editAdminPanel))
      navArr = navArr.filter((nav) => nav.name !== "Settings");

    if (
      !Ability.can(DesignProjectPermissions.indexDesignProject) &&
      !Ability.can(DesignProjectPermissions.showDesignProjectOwned)
    )
      navArr = navArr.filter((nav) => nav.name !== "Design Projects");

    if (
      !Ability.can(ProductionOrderPermissions.indexProductionOrder) &&
      !Ability.can(ProductionOrderPermissions.showProductionOrderOwned)
    )
      navArr = navArr.filter((nav) => nav.name !== "Production Orders");

    if (
      !Ability.can(QuotePermissions.indexQuote) &&
      !Ability.can(QuotePermissions.showQuoteOwned)
    )
      navArr = navArr.filter((nav) => nav.name !== "Quotes");

    return navArr;
  }

  @Watch("logout")
  async onLogout() {
    if (this.logout === 1) {
      await this.axios.post("/logout");
      window.localStorage.removeItem("token");
      this.logout = 0;
      this.$router.push({ name: "UserLogin" });
    }
  }

  get isContainer() {
    const isNotLogin = this.$route.name !== "UserLogin";
    const isNotSettings =
      this.$route.matched.findIndex(
        (match) => match.name === "SettingsPage"
      ) === -1;

    return isNotLogin && isNotSettings;
  }
}
