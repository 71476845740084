enum ProductionOrderPermissions {
  indexProductionOrder = "INDEX_PRODUCTION_ORDER",

  storeProductionOrder = "STORE_PRODUCTION_ORDER",

  updateProductionOrder = "UPDATE_PRODUCTION_ORDER",
  updateProductionOrderOwned = "UPDATE_PRODUCTION_ORDER_OWNED",

  showProductionOrder = "SHOW_PRODUCTION_ORDER",
  showProductionOrderOwned = "SHOW_PRODUCTION_ORDER_OWNED",

  deleteProductionOrder = "DELETE_PRODUCTION_ORDER",
}

export default ProductionOrderPermissions;
