import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/quotes",
    name: "IndexQuote",
    component: () =>
      import(
        /* webpackChunkName: "quote" */ "../views/quote/index/IndexQuote.vue"
      ),
  },
  {
    path: "/quote/:id",
    name: "ShowQuote",
    component: () =>
      import(
        /* webpackChunkName: "quote" */ "../views/quote/show/ShowQuote.vue"
      ),
  },
  {
    path: "/quote/create",
    name: "CreateQuote",
    component: () =>
      import(
        /* webpackChunkName: "quote" */ "../views/quote/create/CreateQuote.vue"
      ),
  },
  {
    path: "/quote/edit/:id",
    name: "EditQuote",
    component: () =>
      import(
        /* webpackChunkName: "quote" */ "../views/quote/create/CreateQuote.vue"
      ),
  },
  {
    path: "/quote/duplicate/:id",
    name: "DuplicateQuote",
    component: () =>
      import(
        /* webpackChunkName: "quote" */ "../views/quote/create/CreateQuote.vue"
      ),
  },
];

export default routes;
