import { NBadge, NButton, NotificationType, useNotification } from "naive-ui";
import { Options, Vue } from "vue-class-component";

import { Bell } from "@vicons/fa";
import { h } from "vue";
import { Channel } from "laravel-echo";

import { getModule } from "vuex-module-decorators";

import store from "@/store/index";
import app from "@/store/app";
import { INotification } from "@/interfaces/app";
import Helper from "@/helper";
import router from "@/router";
const appModule = getModule(app, store);

@Options({
  components: {
    NBadge,
    Bell,
  },
})
export default class NotificationSidebar extends Vue {
  notification = useNotification();

  channel!: Channel;

  get notifications() {
    return appModule.notifications;
  }

  async created() {
    await appModule.subscribeToChannel("test-channel");

    appModule.channelEventListener({
      channel: "test-channel",
      event: "test-event",
      callback: (data: INotification) => {
        this.showNotification(data);
        appModule.pushNotification(data);
      },
    });

    await appModule.subscribeToChannel("notification-user-14");

    appModule.channelEventListener({
      channel: "notification-user-14",
      event: "notify-user",
      callback: (data: INotification) => {
        this.showNotification(data);
        appModule.pushNotification(data);
      },
    });

    // // const channel = window.Echo.channel("test=channel");
    // // console.log(channel);

    // channel.listen("test-event", (data: INotification) => {

    // });
  }

  showNotification(data: INotification) {
    if (!data) return;
    const notificationData = {
      title: data.title,
      description: data.description,
      content: data.content,
      meta: Helper.datetimeToString(data.created_at),
      duration: 5000,
      onClose: () => {
        appModule.flagAsRead([data]);
        console.log("closed");
      },
      action: () =>
        h(
          NButton,
          {
            text: true,
            type: "primary",
            onClick: () => {
              router.push(data.action);
            },
          },
          {
            default: () => "Go To...",
          }
        ),
    };
    this.notification[(data.type as NotificationType) ?? "info"](
      notificationData
    );
  }

  showNotifications() {
    appModule.flagAsRead(this.notifications);

    this.notifications.forEach((notification) =>
      this.showNotification(notification)
    );
  }

  get unreadNotificationsCount() {
    return this.notifications.filter((notification) => !notification.read_at)
      .length;
  }
}
