import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/production-orders",
    name: "IndexProductionOrder",
    component: () =>
      import(
        /* webpackChunkName: "production-order" */ "../views/production-order/index/IndexProductionOrder.vue"
      ),
  },
  {
    path: "/production-order/:id",
    name: "ShowProductionOrder",
    component: () =>
      import(
        /* webpackChunkName: "production-order" */ "../views/production-order/show/ShowProductionOrder.vue"
      ),
  },
  {
    path: "/production-order/create",
    name: "CreateProductionOrder",
    component: () =>
      import(
        /* webpackChunkName: "production-order" */ "../views/production-order/create/CreateProductionOrder.vue"
      ),
  },
  {
    path: "/production-order/edit/:id",
    name: "EditProductionOrder",
    component: () =>
      import(
        /* webpackChunkName: "production-order" */ "../views/production-order/create/CreateProductionOrder.vue"
      ),
  },
  {
    path: "/production-order/duplicate/:id",
    name: "DuplicateProductionOrder",
    component: () =>
      import(
        /* webpackChunkName: "production-order" */ "../views/production-order/create/CreateProductionOrder.vue"
      ),
  },
];

export default routes;
