import { Options, Vue } from "vue-class-component";

import {
  NInput,
  NForm,
  NFormItemGridItem as NFormGi,
  FormValidationError,
} from "naive-ui";
import { ref } from "vue";
import Validator from "@/validation";

import {
  CloseRound as InvalidIcon,
  CheckRound as ValidIcon,
  IndeterminateCheckBoxOutlined as IndeterminateIcon,
} from "@vicons/material";

@Options({
  components: {
    NInput,
    NForm,
    NFormGi,

    InvalidIcon,
    ValidIcon,
    IndeterminateIcon,
  },
})
export default class VatValidator extends Vue {
  // eslint-disable-next-line
  form = ref<any | null>(null);

  vatNumber = "";
  isValid = null;
  loadingResponse = false;

  async validate() {
    this.form
      .validate(async (errors: Array<FormValidationError> | undefined) => {
        console.log("submited");

        if (errors) return;
        this.loadingResponse = true;
        const response = await this.axios.get("/validate-vat", {
          params: {
            number: this.vatNumber,
          },
        });

        this.isValid = response.data.valid;

        this.loadingResponse = false;
      })
      .catch((e: Error) => {
        return e;
      });
  }

  rules = {
    number: [
      {
        validator: () => Validator.required(this.vatNumber, "Number"),
        trigger: "blur",
      },
    ],
  };
}
