// /* eslint-disable @typescript-eslint/no-explicit-any */
class Helper {
  public static dateToString(
    date: string,
    delimiter?: string,
    endDelimiter?: boolean
  ): string;
  public static dateToString(
    date: Date,
    delimiter?: string,
    endDelimiter?: boolean
  ): string;

  public static dateToString(
    date: string | Date,
    delimiter = ".",
    endDelimiter = true
  ) {
    const myDate = typeof date === "string" ? new Date(date) : date;
    const year = myDate.getFullYear();
    const month = String(myDate.getMonth() + 1).padStart(2, "0");
    const day = String(myDate.getDate()).padStart(2, "0");

    return (
      `${day}${delimiter}${month}${delimiter}${year}` +
      (endDelimiter ? delimiter : "")
    );
  }

  public static datetimeToString(
    date: string,
    dateDelimiter?: string,
    endDelimiter?: boolean
  ): string;
  public static datetimeToString(
    date: Date,
    dateDelimiter?: string,
    endDelimiter?: boolean
  ): string;

  public static datetimeToString(
    date: string | Date,
    dateDelimiter = ".",
    endDelimiter = true
  ) {
    const myDate = typeof date === "string" ? new Date(date) : date;
    const year = myDate.getFullYear();
    const month = String(myDate.getMonth() + 1).padStart(2, "0");
    const day = String(myDate.getDate()).padStart(2, "0");

    const hours = String(myDate.getHours()).padStart(2, "0");
    const min = String(myDate.getMinutes()).padStart(2, "0");

    return (
      `${day}${dateDelimiter}${month}${dateDelimiter}${year}` +
      (endDelimiter ? dateDelimiter : "") +
      ` ${hours}:${min}`
    );
  }

  public static dateInputToString(dateString: string) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    // console.log(date, new Date(date));

    // console.log(new Date(date).getDate());

    return `${year}-${month}-${day}`;
  }

  public static simplify(
    objects: Record<string, any> | Array<Record<string, any>>,
    keys: string[] = []
  ) {
    if (Array.isArray(objects)) {
      const tempObjects = JSON.parse(JSON.stringify(objects));
      tempObjects.forEach((object: Record<string, any>) => {
        this.deepIterator(
          object,
          (object: Record<string, any>, property: unknown, key: string) => {
            if (!property) return;
            if (typeof property !== "object") return;

            // Delete property if it's object and not in exceptions
            const findIndex = keys.findIndex((k) => key === k);
            if (findIndex === -1) delete object[key];
          }
        );
      });

      return tempObjects;
    } else {
      const tempObject = JSON.parse(JSON.stringify(objects));
      this.deepIterator(
        tempObject,
        (object: Record<string, any>, property: unknown, key: string) => {
          if (!property) return;
          if (typeof property !== "object") return;

          // Delete property if it's object and not in exceptions
          const findIndex = keys.findIndex((k) => key === k);
          if (findIndex === -1) delete object[key];
        }
      );

      return tempObject;
    }
  }

  private static deepIterator(
    object: Record<string, any>,
    callback: (
      object: Record<string, any>,
      property: unknown,
      key: string
    ) => void
  ) {
    if (typeof object !== "object") return;
    if (object === null) return;

    if (!Array.isArray(object)) {
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const property = object[key];
          callback(object, property, key);

          if (property) this.deepIterator(property, callback);
        }
      }
    } else {
      object.forEach((obj) => {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const property = obj[key];
            callback(obj, property, key);

            if (property) this.deepIterator(property, callback);
          }
        }
      });
    }
  }
}

export default Helper;
