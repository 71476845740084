import { getModule } from "vuex-module-decorators";
import store from "@/store/index";
import app from "@/store/app";
import { User } from "./interfaces/app";
const appModule = getModule(app, store);

class Ability {
  public static can(privilege: string, resourceOwnerId?: number) {
    const user = appModule.user as User;

    // Has access to any resource
    const index = user.permissions.findIndex((p) => p.name === privilege);
    if (index !== -1) return true;

    // Has access to specific resource
    if (user.id !== resourceOwnerId) return false;
    const ownedIndex = user.permissions.findIndex(
      (p) => p.name === `${privilege}_OWNED`
    );
    if (ownedIndex !== -1) return true;

    return false;
  }
}

export default Ability;
