import { createStore } from "vuex";

import app from "./app";

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { app },
});

export default store;
