import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomeView from "@/views/dash-board/DashBoard.vue";
import UserLogin from "@/views/user-login/UserLogin.vue";
import NotFound from "@/404.vue";

import DesignProjectRoutes from "./design-project";
import ProductionOrderRoutes from "./production-order";
import QuoteRoutes from "./quote";
import SettingsRoutes from "./settings";

const routes: Array<RouteRecordRaw> = [
  ...DesignProjectRoutes,
  ...ProductionOrderRoutes,
  ...QuoteRoutes,
  ...SettingsRoutes,
  {
    path: "/",
    name: "Dashboard",
    component: HomeView,
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLogin,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import { getModule } from "vuex-module-decorators";
import store from "@/store/index";
import app from "@/store/app";
import { AxiosError } from "axios";
const appModule = getModule(app, store);

router.beforeEach(async (to, from) => {
  if (to.name === "UserLogin") return true;

  try {
    const error = await appModule.fetchActiveUser();
    if (error && (error as AxiosError).response?.status === 401) {
      window.localStorage.removeItem("token");
      return { name: "UserLogin" };
    }
  } catch (e: unknown) {
    console.log(e);
  }

  return true;
});

export default router;
