import { Options, Vue } from "vue-class-component";

import {
  NInput,
  NButton,
  NCard,
  NCheckbox,
  NSpace,
  NForm,
  NFormItem,
  FormValidationError,
  FormRules,
  useMessage,
  useLoadingBar,
} from "naive-ui";
import { ref } from "vue";

import Validator from "@/validation";

import { getModule } from "vuex-module-decorators";
import store from "@/store/index";
import app from "@/store/app";
import { AxiosError } from "axios";
const appModule = getModule(app, store);

@Options({
  components: { NInput, NButton, NCard, NCheckbox, NSpace, NForm, NFormItem },
})
export default class UserLogin extends Vue {
  // eslint-disable-next-line
  formRef = ref<any | null>(null);

  user: User = {
    name: "",
    password: "",
    rememberMe: false,
  };

  rules: FormRules = {
    user: {
      name: [
        {
          validator: () => Validator.required(this.user.name, "Username"),
          trigger: "blur",
        },
      ],
      password: [
        {
          validator: () => Validator.required(this.user.password, "Password"),
          trigger: "blur",
        },
      ],
    },
  };

  created() {
    const token = window.localStorage.getItem("token");
    if (token !== null) {
      this.$router.push({ name: "Dashboard" });
    }
  }

  messenger = useMessage();
  loadingBar = useLoadingBar();

  submitingForm = false;

  submitLoginForm() {
    this.formRef
      .validate(async (errors: Array<FormValidationError> | undefined) => {
        try {
          if (errors) return;

          this.submitingForm = true;
          this.loadingBar.start();

          const fd = new FormData();
          fd.append("name", this.user.name);
          fd.append("password", this.user.password);

          const response = await this.axios.post("/login", fd);
          const token = response.data.token;
          window.localStorage.setItem("token", token);

          this.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;

          await appModule.fetchActiveUser();

          this.submitingForm = false;
          this.loadingBar.finish();

          this.$router.push({ name: "Dashboard" });
        } catch (error: unknown) {
          const e = error as AxiosError;
          const status = e.response?.status as number;

          if (status === 401)
            this.messenger.error(
              "Failed to login user. Invalid username or password."
            );
          else
            this.messenger.error(
              `Status: ${status}. Failed to login user. Please contact system admin.`
            );

          this.submitingForm = false;
          this.loadingBar.error();
        }
      })
      .catch((e: Error) => {
        return e;
      });
  }
}

interface User {
  name: string;
  password: string;
  rememberMe: boolean;
}
